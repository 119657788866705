.sidemenu {
	width: 250px;
	height: 100vh;
	background-color: white;
	box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.logo {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.logo-icon {
	width: 30px;
	height: 30px;
}

.logo-text {
	font-size: 20px;
	font-weight: bold;
	margin-left: 10px;
}

.menu {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.menu-item {
	display: flex;
	align-items: center;
	padding: 12px 15px;
	cursor: pointer;
	border-radius: 8px;
	color: #555;
	transition: background 0.3s, color 0.3s;
}

.menu-item span {
	margin-left: 10px;
	font-size: 16px;
}

.menu-item:hover,
.menu-item.active {
	background-color: #f0f0f0;
	color: #000;
	font-weight: bold;
}
