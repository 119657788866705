/* FONTS */
@font-face {
	font-family: "Milliard SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("Milliard SemiBold"),
		url("../../fonts/Milliard-SemiBold.woff") format("woff");
}
.about-h {
	position: relative;
	top: -20px;
}
.about_banner {
	width: 80%;
}
.abfontstyle {
	font-size: 18px !important;
	font-weight: 400px !important;
}
.abwh {
	background-color: rgba(204, 203, 248, 0.2);
	padding: 30px;
}
.abwh div {
	padding: 10px;
}
.abtxt {
	text-align: center;
}
.abtxt span {
	width: 50%;
	padding: 20px;
}
.abwh h3 {
	padding: 0 !important;
}
.ppp{
	margin: 30px 0 !important;
}
.abwhy {
	flex-direction: row;
	align-items: flex-start;
}
.ab-lpro {
	flex-direction: row;
}
@media (max-width: 1100px) {
	.about_banner {
		width: 90%;
	}
}

@media (max-width: 944px) {
	.about_banner {
		width: 100%;
	}
	.abtxt span {
		width: 90%;
	}
	.abwhy {
		flex-direction: column;
	}

	.ab-lpro {
		flex-direction: column;
	}
}
