* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none !important;
}

@import url("https://fonts.googleapis.com/css?family=Bangers&display=swap");

.App {
	transition: margin-left 2.7s;
	overflow: hidden;
	width: 100%;
}
.hamburg img {
	width: 30px;
	position: absolute;
}
.Nav_mob {
	display: none;
}
.menu-item img {
	width: 20px;
	height: 20px;
	position: relative;
	margin-right: 10px;
	top: 3px;
}
.navlist ul,
.navlist li {
	list-style: none;
}

.navlist a {
	text-decoration: none;
}
.menu-items {
}
nav a {
	display: flex;
}
aside {
	font-size: 1.5rem;
	transition: all 300ms ease-in-out;
}

.wnav {
	font-family: pangram L !important;
	background: #ffffff;
	color: #000;
	height: 100%;
	width: 0;
	position: fixed;
	top: 108px;
	left: 0;
	padding-top: 60px;
	transition: 0.7s all ease-in-out;
	font-size: 14px;
	z-index: 400;
	border-radius: 10px;
}
.txt {
	position: relative;
	top: -40px;
	left: 20px;
}
.mo_head {
	padding: 20px;
	display: flex;
	justify-content: space-between;
}
.mo_head span img {
	position: relative;
	top: 3px;
	cursor: pointer;
	width: 15px;
}
.mo_foot {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}
.mo_foot_single {
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}
.mo_btn_single {
	background-color: rgba(89, 86, 233, 0.5);
	border-radius: 40px;
	padding: 12px;
	color: #fff;
	width: 100%;
	margin-bottom: 30px;
}
.mo_btn_single.active {
	background-color: rgba(89, 86, 233, 1);
}
.mo_foot button {
	border-radius: 40px;
	width: 100%;
}
.chek {
	background-color: transparent;
	border: 1.5px solid #e6e6e6 !important;
}
.teees {
	position: absolute;
	right: -120px;
	position: relative;
	top: -9px;
	background: #310000;
	padding: 12px 14px 10px 17px;
}
.border {
	/* width: 286px; */
	height: 0px;
	top: -20px;
	position: relative;
	border: 0.1px solid rgba(239, 252, 255, 0.5) !important;
}

.opneSidebar {
	transition: 0.7s;
	width: 308px;
	height: 82%;
	margin-left: 41px;
}
.overL {
	position: fixed;
	inset: 0px;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	z-index: 100000;
}

#close {
	font-size: 4.3rem;
	position: absolute;
	right: 2rem;
	top: 1rem;
}

.to-right {
	/* margin-left: 220px; */
	top: 60px;
	position: fixed;
	margin-left: 390px !important;
	transition: all 300ms ease-in-out;
	width: 36%;
	z-index: 30;
}

@media (max-width: 650px) {
	.to-right {
		margin-left: 300px !important;
	}
	.opneSidebar {
		margin-left: 0px;
		width: 270px;
	}
}
@media (max-width: 341px) {
	.to-right {
		margin-left: 257px !important;
		margin-top: -10px;
	}
}
.yo {
	display: none !important;
}
.closes {
	display: none;
}
.navlist {
	position: relative;
	left: 20px;
	top: -70px;
} 
.navlists_kk {
	top: 0 !important;
}
.navlist li {
	margin: 20px 0;
	transition: all 300ms ease-in-out;
}
.navlist li a {
	color: #4a4a4a;
	font-size: 15px;
	transition: all 300ms ease-in-out;
}
.navlist li a img {
	position: relative;
	top: -0.5px;
}

.navlist li a:hover {
	background: #eeeefd;
	padding: 20px 20px;
	width: 100%;
}
/* MOBILE VERSION:  */
@media screen and (max-width: 974px) {
	.to-right {
		margin-left: 178px;
	}
}
/* SMALLER MOBILE VERSION:  */
@media screen and (max-width: 768px) {
	.to-right {
		margin-left: 158px;
	}
}
.dashBT {
	position: absolute;
	top: 88px;
	left: 44%;
	width: 168px;
	height: 34px;
	background: #1c2230;
	border: 0.8px solid rgba(250, 250, 250, 0.7);
	border-radius: 40px;
	text-align: center;
	padding-top: 7px;
}

.dashBT h4 {
	font-family: "Montserrat-SemiBold" !important;
	font-size: 14px;
}
.dashblur {
	opacity: 0.3;
	position: fixed;
	top: 98px;
}
/* SMALLER MOBILE VERSION:  */
@media screen and (max-width: 1230px) {
	.dashBT {
		left: 42%;
	}
}
@media (max-width: 818px) {
	.Nav_mob {
		display: block;
	}
	.Nav_up {
		display: block;
		margin-top: -30px;
	}
	.user_left {
		margin-left: 0;
	}
	.window_nav {
		display: none;
	}
}

@media screen and (max-width: 899px) {
	.dashBT {
		left: 40%;
	}
}
@media screen and (max-width: 640px) {
	.dashBT {
		left: 37%;
	}
}
@media screen and (max-width: 565px) {
	.dashBT {
		left: 35%;
	}
}

@media screen and (max-width: 480px) {
	.dashBT {
		left: 30%;
	}
}
@media (max-width: 360px) {
	.dashBT {
		left: 26%;
	}
}
