/* Fonts */
/* @font-face {
	font-family: "Milliard Book";
	font-style: normal;
	font-weight: normal;
	src: local("Milliard Book"),
		url("./Components/fonts/Milliard.woff") format("woff");
}

@font-face {
	font-family: "Lato Black";
	font-style: normal;
	font-weight: normal;
	src: local("Lato Black"),
		url("./Components/fonts/Lato-Black.woff") format("woff");
} */
.userbg {
	font-family: "Montserrat-Regular" !important;
}
.wit_btm .dsh_btn {
	color: #fff ;
	cursor: pointer;
}
.wit_btm .dsh_btn:hover {
	color: #fff ;
	background-color: rgba(89, 86, 233, 0.8);
}
.userbg h3 {
	font-family: "Montserrat-Bold" !important;
	font-size: 28px !important;
}
.user_app {
	width: 90%;
	margin: auto auto auto auto;
}
.tnew {
	margin-top: 80px;
	margin-left: 200px;
}
.userbg {
	position: fixed;
	overflow-y: scroll !important;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	background-color: #fff;
	color: #000;
}
.user_cont_end {
	position: relative;
	top: 370px;
	border-radius: 20px;
	padding: 40px;
}
.withp {
	position: relative;
	top: 370px;
	border-radius: 20px;
}
.user_cont_end > button {
	background: transparent;
	color: #5956e9;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	margin-right: 40px;
	padding-bottom: 15px;
	outline: none;
	font-family: "Montserrat-SemiBold" !important;
}
.user_cont_active {
	padding-bottom: 6px;
	border-bottom: 2px solid #fff;
}
.light {
	color: #858582 !important;
}
@media (max-width: 818px) {
	.user_app {
		position: relative;
		width: 95%;
	}
}

