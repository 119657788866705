.miniFoot { 
	text-align: center;
	overflow: hidden;
	color: #000; 
	opacity: 0.9;
}

.miniFoot p span {	
	width: 390px;
	margin-top: 20px;
} 
.mnf-btn {
	color: #fff;
	background-color: #5956e9;
	padding: 0 15px;
}

.miniFoot img {
	margin: 0 8px 0 30px;
}
@media (max-width: 340px) {
	.miniFoot img {
		margin: 0 8px 0 12px;
	}
}
/* @media (max-width: 320px) {
	.miniFoot .main-btn{
		
	}
} */
@media (max-width: 320px) {
	.miniFoot img {
		margin: 0 8px 0 0px;
	}
	.miniFoot .main-btn {
		margin-bottom: 20px;
		margin-right: 15px;
	}
}
.miniFoot span a {
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #0f172a;
}
@media (max-width: 650px) {
	.miniFoot h1 {
		font-size: 30px;
	}
	.miniFoot {
		overflow-x: hidden;
	}
}
@media (max-width: 349px) {
	.miniFoot p {
		width: 100%;
	}
}
.miniFoot h1 {
	margin-bottom: 30px;
}
.miniFoot p {
	margin-bottom: 30px;
}
