.user_head {
	padding: 20px;
	font-family: "Milliard Book";
	border-bottom: 1px solid #e6e6e6;
	position: relative;
	top: 0;
}
/* mobile stops at 287 */
.user_app img {
	width: 50%;
}
.logo_class {
	position: relative;
	top: -1px;
	margin-right: 40px;
}

.logo_class img {
	width: 130px !important;
}
.user_logo {
	width: 143px !important;
	
}
.user_initials {
	width: 35px;
	height: 35px;
	background-color: rgba(114, 177, 210, 1); /* Blue background */
	color: white;
	font-weight: bold;
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%; /* Makes it circular */
	text-transform: uppercase;
}

.user_app span {
	font-weight: bolder;
}
.user_right {
	position: absolute;
	right: 0;
	top: 17px;
}
.user_lang {
	margin-left: 30px;
	font-weight: normal !important;
}
.user_head .header_head {
	font-weight: 500px;
	font-size: 20px;
}
.lng {
	margin-right: 30px;
}

.lngtxt {
	font-weight: normal !important;
	margin-right: 10px;
}
.user_profile {
	position: relative;
	right: 10px;
}

.dropdown {
	background-color: transparent;
	color: red;
	border: none;
	/* position: absolute;
	right: 0px; */
	top: 1.9px;
	cursor: pointer !important;
}
.lng select {
	background-color: transparent;
	outline: none;
	cursor: pointer !important;
}

.user_lang select {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
.user_lang_mobile select {
	margin-right: 5px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
.window_nav {
	width: 10px;
	position: absolute;
	top: 10px;
}
.user_left {
	margin-left: 70px;
}
.window_nav .navbar-toggler {
	border: 0 ;
	outline: 0;
	box-shadow: none;
}

.window_nav .navbar-toggler:focus {
	border: 0 ;
	outline: 0;
	box-shadow: none;
}  
@media (max-width: 800px) {
	.logo_class {
		display: none;
	}
}
@media (max-width: 650px) {
	.user_logo {
		width: 130px !important;
	}
	.user_head .header_head {
		font-size: 16px !important;
	}
	.user_right {
		font-size: 13px;
		top: 14px;
	}
}
@media (max-width: 550px) {
	.user_head .header_head {
		font-size: 14px !important;
		width: 160px;
	}
	.user_head img:first-child {
		top: -10px !important;
	}
	
}
@media (max-width: 438px) {
	.user_head .header_head {
		font-size: 14px !important;
		width: 120px;
	}
	.user_head img:first-child {
		top: -19px !important;
	}
	.lngtxt {
		display: none !important;
	}
	.user_right {
		font-size: 13px;
		
	}
}
@media (min-width: 651px) {
	.user_lang_mobile select {
		display: none !important;
	}
}
@media (max-width: 336px) {
	.user_logo {
		width: 120px !important;
	}
	.user_initials {
		width: 33px;
		height: 33px;
		
	}
	.user_head .header_head {
		font-size: 14px !important;
		width: 110px;
	}
}
