.bg-color-dark {
	background-color: #f6f6f6;
	text-align: center;
	padding: 25px 0;
}

@import url("https://fonts.cdn.com/MabryPro.css");
.bg-color-dark * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.bg-color-dark {
	--bg-color: #f6f8fc;
	--primary-text-color: #212943;
	--secondary-text-color: #9ea6ae;
	--details: #dee4f1;

	font-family: "Mabry Pro", sans-serif;
	font-size: 10px;
	background: var(--bg-color);
}

.bg-color-dark .heading {
	color: var(--primary-text-color);
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 2rem;
}

.bg-color-dark .container {
	margin: 0 auto;
	padding: 2rem;
	text-align: center;
}

.bg-color-dark form {
	display: flex;
	width: 35rem;
	background-color: #fff;
	align-items: center;
	margin: 0 auto;
	border-radius: 1rem;
	position: relative;
}

.bg-color-dark form svg {
	height: 1rem;
	fill: var(--secondary-text-color);
	position: absolute;
	left: 1rem;
	width: 4rem;
}

.bg-color-dark .searchbar {
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	font-weight: 400;
	border: none;
	padding: 1rem;
	padding-left: 4rem;
	width: 100%;
	box-shadow: 0px 5px 13px 0px var(--details);
	border-radius: 0.8rem;
}

.bg-color-dark .searchbar:focus {
	outline: none;
	box-shadow: 0 0 0 1.5px var(--details), 0px 5px 13px 0px var(--details);
}

.bg-color-dark .faq {
	margin: 3rem 0;
}
.bg-color-dark .faq-item {
	margin: 30px;
	padding: 0 76px;
	text-align: center;
}
.bg-color-dark .question-wrapper {
	width: 35rem;
	border-bottom: 1px solid var(--details);
	margin: 0 auto;
	padding: 1rem;
	transition: 1s;
}

.bg-color-dark .question {
	display: flex;
	font-size: 1rem;
	font-weight: 500;
	color: var(--primary-text-color);
	justify-content: space-between;
	align-items: center;
}

.bg-color-dark .question svg {
	width: 80px;
	height: 25px;
	fill: #5956e9;
	transition: transform 0.3s ease;
}
.bg-color-dark .question h4 {
	font-size: 20px;
	font-weight: 400;
	line-height: 22px;
}

.bg-color-dark .question svg.active {
	transform: rotate(180deg);
}

.bg-color-dark .question svg:hover {
	opacity: 0.8;
}

.bg-color-dark button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 1.2rem;
}

.bg-color-dark button:focus {
	outline: none;
}

.bg-color-dark .answer {
	display: none;
	text-align: left;
	padding-top: 1.5rem;
	font-weight: light;
	font-size: 0.8rem;
	line-height: 1.5;
	color: var(--secondary-text-color);
}

.bg-color-dark .answer.active {
	display: block;
	height: 0%;
	animation: slidein 0.4s forwards;
}

@keyframes slidein {
	from {
		opacity: 0.3;
		transform: translateY(-20%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}
@media (max-width: 991px) {
	.bg-color-dark .faq-item {
		margin: 30px 0px;
		padding: 0 0px;
	}
	.bg-color-dark .question h4 {
		font-size: 15px;
		font-weight: 300;
		text-align: left;
	}
}
