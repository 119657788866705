.pricingtable {
	padding-bottom: 80px;
}

.v_opa_none {
	opacity: 0;
}
.pricecont {
	margin: 50px 0;
	padding: 30px 0;
}
.table-hr {
	border: 0.5px solid rgba(100, 116, 139, 0.8);
	margin-top: 22px;
}
.slick-dots li {
	position: relative;
	top: 10px;
	width: 7px !important;
	height: 7px !important;
	color: #d9d9d9;
	background: #d9d9d9;
	border-radius: 50px;
}
.slick-dots .slick-active {
	background-color: #5956e9;
}
.pricecont h1,
.pricecont p {
	text-align: center;
	margin-bottom: 20px;
}
.pricecont h4 {
	text-align: center;
	font-weight: 500;
	font-size: 24px;
}
.tabed-content {
	width: 85%;
	margin: auto;
}
.mobile {
	display: none;
}
.mob {
	display: none;
}
.tPlan {
	width: 30px;
	position: absolute;
	top: 30px;
	right: 27%;
}
.table-btn {
	text-align: center;
	width: 100%;
	height: 44px;
	background: #5956e9;
	border-radius: 6px;
	color: white;
	margin: 20px 0 0 0;
	padding: 9px 24px;
	gap: 10px;
	border-radius: 40px;
}
.table-btn-w {
	text-align: center;
	width: 100%;
	height: 44px;
	background: #f6f6f6;
	border-radius: 6px;
	color: #5956e9;
	margin: 20px 0 0 0;
	padding: 9px 24px;
	gap: 10px;
	border-radius: 40px;
}

.pricing-one__single:hover {
	background-color: #5956e9;
	color: white !important;
}
.pricing-one__single:hover .table-hr {
	border: 0.5px solid #fff;
}
.pricing-one__single:hover .pricig-heading .price-range,
.pricing-one__single:hover .pricig-heading h6,
.pricing-one__single:hover .pricing-one__single .pricig-body ul li {
	color: white !important;
}
.pricing-one__single:hover .table-btn,
.pricing-one__single:hover .pricig-body ul li i {
	background-color: white;
	color: #5956e9;
}
@media (max-width: 1400px) {
	.tabed-content {
		width: 90%;
	}

	.pricing-one__single {
		width: 100% !important;
	}
}
/*==================
    MAIN PRICING css 
==================*/
.appie-pricing-area {
	background: #eef1f6;
}

.appie-pricing-area {
	position: relative;
	z-index: 10;
}
.appie-pricing-area .section-title {
	padding-bottom: 48px;
}

.appie-pricing-area .switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	vertical-align: middle;
	margin: 0;
}

.appie-pricing-area .switch input {
	display: none;
}

.appie-pricing-area .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	background: #fff;
}

.appie-pricing-area .slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	-webkit-transform: translateX(0px);
	transform: translateX(0px);
	background-color: #2b70fa;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.appie-pricing-area .switch.off .slider:before {
	-webkit-transform: translateX(26px);
	transform: translateX(26px);
}

.appie-pricing-area .slider.round {
	border-radius: 34px;
}

.appie-pricing-area .slider.round:before {
	border-radius: 50%;
}

.appie-pricing-area ul.switch-toggler-list {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
	margin-bottom: 40px;
}

.appie-pricing-area ul.switch-toggler-list li a {
	font-size: 14px;
	font-weight: 500;
	color: #505056;
	padding-left: 20px;
	padding-right: 20px;
	display: block;
}

.appie-pricing-area ul.switch-toggler-list li.active a {
	color: #0e1133;
}

.pricing-one__single {
	width: 330px;
	padding: 30px 20px 40px 25px;
	border-radius: 20px;
	border: 1.5px solid #e6e6e6;
	position: relative;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.price_checks img{
	margin-right: 10px;
	}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pricing-one__single {
		margin-bottom: 30px;
		width: 100% !important ;
	}
}
@media (max-width: 1015px) {
	.pricing-one__single {
		width: 105% !important ;
	}
}
@media (max-width: 991px) {
	.pricing-one__single {
		width: 100% !important ;
	}
}
@media (max-width: 991px) {
	.pricing-one__single {
		margin-bottom: 30px;
		width: 60% !important;
		margin: auto;
	}
	
	.mobile {
		display: block;
		margin-top: -59px;
		padding: 0;
	}
	.mob {
		display: block;
	}
	.windowt {
		display: none !important;
	}
}
@media (max-width: 452px) {
	.mobile {
		padding: 0;
		margin: 0;
	}
}
@media (max-width: 650px) {
	.pricing-one__single {
		width: 80% !important;
	}
}
@media (max-width: 506px) {
	.pricing-one__single {
		width: 100% !important;
		margin: 0 !important;
	}
}
@media (max-width: 506px) {
	.pricingtable {
		width: 100% !important;
		padding: 0 !important;
	}
	.pTable {
		padding-bottom: 50px;
	}
}
.pricing-one__single .pricig-heading {
	display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content horizontally */
    justify-content: center; /* Centers the content vertically */
    text-align: center;
}

.pricing-one__single .pricig-heading img {
    margin: 15px 0; /* Removes any default margin */
	width: 50px;
    max-width: 100%; /* Makes sure it doesn't overflow */
    height: auto; /* Keeps the aspect ratio */
}

.pricing-one__single .pricig-heading h6 {
	font-size: 24px;
	font-weight: 590;
	text-align: center;
}

.pricing-one__single .pricig-heading .price-range {
	font-size: 24px;
	text-align: center;
	font-weight: 400;
	color: #0e1133;
}
.pricing-one__single .pricig-heading .price-range sup {
	font-size: 24px;
}
.pricing-one__single .pricig-heading .price-range span {
	font-size: 19px;
	font-weight: 540;
}
.pricing-one__single .pricig-heading .price-range p {
	display: inline-block;
	font-size: 14px;
}
.pricing-one__single .pricig-heading > p {
	font-size: 14px;
}
.pricing-one__single .pricig-body {
	padding-top: 25px;
}
.pricing-one__single .pricig-body ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.pricing-one__single .pricig-body ul li {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 10px;
}
.pricing-one__single .pricig-body ul li i {
	height: 26px;
	width: 26px;
	font-size: 14px;
	text-align: center;
	line-height: 26px;
	border-radius: 50%;
	background: #64748b;
	color: #fff;
	margin-right: 10px;
}
.pricing-one__single .pricig-body .pricing-btn a {
	background: transparent;
	color: #0e1133;
	border: 2px solid #e7eaef;
}
.pricing-one__single .pricig-body .pricing-btn a:hover {
	background: #f8452d;
	color: #fff;
	border-color: #f8452d;
}
.pricing-one__single .pricing-rebon {
	position: absolute;
	right: 30px;
	top: -14px;
}
.pricing-one__single .pricing-rebon span {
	font-size: 14px;
	font-weight: 500;
	color: #fff;
	background: #f8452d;
	line-height: 28px;
	padding: 0 18px;
	border-radius: 30px;
	box-shadow: 0px 10px 20px 0px rgba(183, 30, 10, 0.3);
}
.pricing-one__single.center {
	box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}
.pricing-one__single.center .pricig-body ul li i {
	color: #f8452d;
}
.pricing-one__single.center .pricig-body .pricing-btn a {
	background: #f8452d;
	border-color: #f8452d;
	color: #fff;
}
.pricing-one__single:hover {
	box-shadow: 0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}
.pricing-one__single.pricing-one__single_2 {
	border: 1px solid #eef0f4;
}
.pricing-one__single.pricing-one__single_2 .pricig-body ul li i {
	background: #eef4fe;
	color: #3172f5;
}
.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-btn a {
	border-radius: 30px;
}
.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-btn a:hover {
	background: #ff3e66;
	border-color: #ff3e66;
}
.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-rebon {
	right: 0;
	top: 20px;
}
.pricing-one__single.pricing-one__single_2 .pricig-body .pricing-rebon span {
	border-radius: 30px 0 0 30px;
	background: #2a6df5;
	box-shadow: none;
}
.pricing-one__single.pricing-one__single_2.active {
	border-top: 3px solid #ff3e66;
	box-shadow: 0px -3px 0px 0px rgba(255, 62, 102, 0.004),
		0px 40px 40px 0px rgba(14, 17, 51, 0.1);
}
.pricing-one__single.pricing-one__single_2.active .pricig-body .pricing-btn a {
	background: #ff3e66;
	border-color: #ff3e66;
	color: #fff;
}
.pricing-one__single.pricing-one__single_2.item-2 .pricig-body ul li i {
	background: #effaf3;
	color: #31c369;
}
.pricing-one__single.pricing-one__single_2:hover {
	border-color: #fff;
	border-top: 3px solid #ff3e66;
	transform: translateY(-10px);
}

.appie-pricing-2-area .appie-section-title .nav {
	display: inline-block !important;
	border: 2px solid #e7eaef;
	padding: 3px;
	border-radius: 40px;
	margin-top: 55px;
}
.appie-pricing-2-area .appie-section-title .nav li {
	display: inline-block;
}
.appie-pricing-2-area .appie-section-title .nav li a {
	border-radius: 30px;
	font-weight: 500;
	font-size: 14px;
	color: #505056;
	padding: 5px 20px;
}
.appie-pricing-2-area .appie-section-title .nav li a.active {
	color: #fff;
	background: #ff3e66;
}

.appie-pricing-6-area .pricing-one__single .pricing-rebon span {
	background: #9b2cfa;
	box-shadow: none;
}
.appie-pricing-6-area .pricing-one__single.center .pricig-body .pricing-btn a {
	background: #9b2cfa;
	border-color: #9b2cfa;
}
.slick-dots li button {
	opacity: 0;
}