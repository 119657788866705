/*-----------------------------------------------------------------------------------

    Template Name: Sample - Corporate Business Bootstrap4 HTML5 Template
    Template URI: site.com
    Description: Sample - Corporate Business Bootstrap4 HTML5 Template
    Author: MD THAHERIL ISLAM
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    =============

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*==================
    1. COMMON css 
==================*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
	font-weight: normal;
	font-style: normal;
	color: #505056;
}
/* ============================vue */
* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

/* img {
	max-width: 100%;
} */

a:focus,
input:focus,
textarea:focus,
button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover {
	text-decoration: none !important;
}

i,
span,
a {
	display: inline-block;
	transition: all linear 0.3s;
}

h1 {
	font-size: 48px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

ul,
ol {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #505056;
	margin: 0px;
}

.bg_cover {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
}

.slick-slide {
	outline: 0;
	padding: 0 20px; /* Adds spacing on left and right of each slide */
  }
  
  
  

/*==== All Button Style ====*/
.main-btn {
	display: inline-block;
	font-weight: 600;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: inherit;
	border: 1px solid #5956e9;
	padding: 0 10px;
	font-size: 15px;
	line-height: 36.5px;
	border-radius: 40px;
	color: #fff;
	cursor: pointer;
	z-index: 5;
	-webkit-transition: all 0.4s ease-out 0s;
	-moz-transition: all 0.4s ease-out 0s;
	-ms-transition: all 0.4s ease-out 0s;
	-o-transition: all 0.4s ease-out 0s;
	transition: all 0.4s ease-out 0s;
	background-color: #5956e9;
}
.main-btn:hover {
	background-color: #fff;
	color: #2b70fa;
	border-color: #2b70fa;
	text-decoration: none !important;
}
.main-btn.main-btn-2 {
	background-color: #fff;
	color: #5956e9;
	border-color: #5956e9;
}
.main-btn.main-btn-2:hover {
	background-color: #5956e9;
	border-color: #5956e9;
	text-decoration: none !important;
	color: #fff;
}

.appie-section-title {
	padding-bottom: 25px;
}
.appie-section-title .appie-title {
	font-size: 44px;
	line-height: 54px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-section-title .appie-title {
		font-size: 36px;
		line-height: 46px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-section-title .appie-title {
		font-size: 36px;
		line-height: 46px;
	}
}
@media (max-width: 767px) {
	.appie-section-title .appie-title {
		font-size: 26px;
		line-height: 36px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-section-title .appie-title {
		font-size: 36px;
		line-height: 46px;
	}
}
.appie-section-title p {
	font-size: 18px;
	line-height: 28px;
	margin-top: 11px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-section-title p {
		font-size: 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-section-title p {
		font-size: 15px;
	}
}
@media (max-width: 767px) {
	.appie-section-title p {
		font-size: 15px;
	}
}
.appie-section-title .main-btn {
	border-radius: 30px;
	border: 2px solid #e7eaef;
	background: #fff;
	color: #0e1133;
}
.appie-section-title .main-btn:hover {
	background: #ff3e66;
	color: #fff;
	border-color: #ff3e66;
}
.appie-section-title.appie-section-title-2 .appie-title {
	color: #fff;
}
.appie-section-title.appie-section-title-2 p {
	color: #fff;
}

.back-to-top {
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: 999;
	display: none;
}
.back-to-top a {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	background: #2b70fa !important;
	border-radius: 6px;
	color: #fff;
}
.back-to-top.back-to-top-2 a {
	background: #ff3e66;
}
.back-to-top.back-to-top-3 a {
	background: #ff6b58;
}
.back-to-top.back-to-top-6 a {
	background: #9b2cfa;
}
.back-to-top.back-to-top-5 a {
	background: #000 !important;
}
.back-to-top.back-to-top-8 a {
	background: #db0f30;
}

.off_canvars_overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9998;
	opacity: 0;
	visibility: hidden;
	background: #232323;
	top: 0;
	transition: all linear 0.3s;
}
.off_canvars_overlay.active {
	opacity: 0.5;
	visibility: visible;
}

.offcanvas_menu {
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.offcanvas_menu {
		display: block;
	}
}
@media (max-width: 767px) {
	.offcanvas_menu {
		display: block;
	}
}

.offcanvas_menu_wrapper {
	width: 290px;
	position: fixed;
	background: #fff;
	z-index: 9999;
	top: 0;
	height: 100%;
	transition: 0.5s;
	left: 0;
	margin-left: -300px;
	padding: 50px 15px 30px;
	overflow-y: visible;
}
.offcanvas_menu_wrapper.active {
	margin-left: 0;
}
.offcanvas_menu_wrapper .slinky-theme-default {
	background: inherit;
	min-height: 300px;
	overflow-y: auto;
}
.offcanvas_menu_wrapper .header-btn {
	margin-bottom: 30px;
}
.offcanvas_menu_wrapper .header-btn a {
	color: #222;
}
.offcanvas_menu_wrapper .header-btn a:hover {
	color: #222;
}

.offcanvas_main_menu li {
	position: relative;
}
.offcanvas_main_menu li:last-child {
	margin: 0;
}
.offcanvas_main_menu li span.menu-expand {
	position: absolute;
	right: 0;
}
.offcanvas_main_menu li a {
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	display: block;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #ededed;
	color: #222;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.offcanvas_main_menu li a:hover {
	color: #2b70fa;
}
.offcanvas_main_menu li ul.sub-menu {
	padding-left: 20px;
}

.offcanvas_footer {
	padding-bottom: 50px;
	text-align: center;
}
.offcanvas_footer span a {
	font-size: 14px;
	color: #222;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.offcanvas_footer span a:hover {
	color: #2b70fa;
}

.offcanvas_menu_wrapper.active .canvas_close {
	opacity: 1;
	visibility: visible;
}

.canvas_close {
	position: absolute;
	top: 10px;
	right: -50px;
	opacity: 0;
	visibility: hidden;
	transition: all linear 0.3s;
}
.canvas_close a {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 500;
	width: 40px;
	height: 40px;
	display: block;
	text-align: center;
	line-height: 40px;
	border: 1px solid #2b70fa;
	border-radius: 10px;
	background: #2b70fa;
	color: #fff;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.canvas_close a:hover {
	background: #2b70fa;
	border-color: #2b70fa;
	color: #fff;
}

.canvas_open a {
	font-size: 26px;
	width: 50px;
	height: 42px;
	display: block;
	line-height: 39px;
	text-align: center;
	border: 1px solid #232323;
	color: #222;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.canvas_open a:hover {
	color: #2b70fa;
	border-color: #2b70fa;
}

.offcanvas-social {
	margin-bottom: 40px;
	margin-top: 40px;
}
.offcanvas-social ul li {
	display: inline-block;
}
.offcanvas-social ul li a {
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 38px;
	color: #2b70fa;
	border: 1px solid #eaeaea;
	border-radius: 50%;
	margin: 0 5px;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.offcanvas-social ul li a:hover {
	background: #2b70fa;
	border-color: #2b70fa;
	color: #fff;
}

.toggle-btn {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 20px;
	color: #0e1133;
}

.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-position: center center;
	background-repeat: no-repeat;
}

.preloader-close {
	position: fixed;
	z-index: 999999;
	color: #fff;
	padding: 10px 20px;
	cursor: pointer;
	right: 40px;
	bottom: 40px;
	font-weight: 600;
	line-height: 60px;
	background: #2b70fa;
	padding: 0 15px;
	border-radius: 5px;
}

.loader-wrap {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
}

.loader-wrap .layer-one {
	position: absolute;
	left: 0%;
	top: 0;
	width: 33.3333%;
	height: 100%;
	overflow: hidden;
}

.loader-wrap .layer-two {
	position: absolute;
	left: 33.3333%;
	top: 0;
	width: 33.3333%;
	height: 100%;
	overflow: hidden;
}

.loader-wrap .layer-three {
	position: absolute;
	left: 66.6666%;
	top: 0;
	width: 33.3333%;
	height: 100%;
	overflow: hidden;
}

.loader-wrap .layer .overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #f4f9ff;
}

/*==================
    2.HEADER css 
==================*/
.appie-sticky.sticky {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1010;
	background: #fff;
	box-shadow: 0 15px 30px 0 #0000000f;
	/* padding-top: 20px;
	padding-bottom: 20px; */
	padding-bottom: -0px !important;
	animation: sticky 1.2s;
}
.container {
	max-width: 97% !important;
}
.offcanvas-brand {
	margin-bottom: 27px;
}
.footer-widget-info a {
	color: black !important;
	padding: 10px 0;
}
.footer-widget-info a:hover {
	color: #125bc9 !important;
}
.appie-header-area {
	/* padding-top: 30px; */
	padding-bottom: 18px;
	position: relative;
	left: 0;
	top: 0;
	right: 0;
	z-index: 999;
}
.appie-header-area.appie-header-2-area .appie-btn-box .main-btn {
	background: #ff3e66;
	border-radius: 30px;
	border-color: #ff3e66;
}
.appie-header-area.appie-header-2-area .appie-btn-box .main-btn:hover {
	background: transparent;
	color: #ff3e66;
}
.appie-header-area.appie-header-3-area.appie-sticky.sticky {
	background: #0e1133;
}
.appie-header-area.appie-header-4-area.appie-sticky.sticky {
	background: linear-gradient(90deg, #6b1fcd 0%, #374ede 100%);
}
.appie-header-area.appie-header-page-area.appie-sticky.sticky {
	background: #0a44b9;
}
.appie-header-area.appie-header-7-area.appie-sticky.sticky {
	background: #290645;
}
.appie-header-area.appie-header-error-page {
	box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
	padding-top: 20px;
	padding-bottom: 20px;
}

.header-nav-box .appie-btn-box {
	position: relative;
} 
.appie-logo-box a {
 display: flex;
} 
.appie-logo-box a span {
	font-weight: bolder; 
	color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-nav-box .appie-btn-box {
		padding-right: 50px;
	}
}
@media (max-width: 767px) {
	.header-nav-box .appie-btn-box {
		padding-right: 30px;
	}
}
.header-nav-box .appie-btn-box .login-btn {
	color: #0e1133;
	font-size: 15px;
	font-weight: 500;
	margin-right: 40px;
	text-decoration: none;
}

.header-nav-box .appie-btn-box .login-btn:hover {
	color: #125bc9;
}
.header-nav-box .appie-btn-box .login-btn i {
	padding-right: 6px;
}
@media (max-width: 767px) {
	.header-nav-box .appie-btn-box .main-btn {
		display: none;
	}
	.header-nav-box .appie-btn-box .login-btn {
		margin-right: 9px;
	}
	.container_bg {
		background-color: transparent;
	}
	
	.container_bg {
		padding: 40px 0 40px 0 !important;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.header-nav-box .appie-btn-box .main-btn {
		padding: 0 10px;
	}
	.container_bg {
		background-color: transparent;
	}
	
}
.header-nav-box.header-nav-box-3 .appie-header-main-menu ul > li > a {
	color: #fff;
}
.header-nav-box.header-nav-box-3
	.appie-header-main-menu
	ul
	> li
	.sub-menu
	li
	a {
	color: #0e1133;
	text-decoration: none !important;
}
.header-nav-box.header-nav-box-3 .appie-btn-box .login-btn {
	color: #fff;
}
.header-nav-box.header-nav-box-3 .appie-btn-box .main-btn {
	background: #ff6b58;
	border-color: #ff6b58;
}
.header-nav-box.header-nav-box-3 .appie-btn-box .main-btn:hover {
	background: transparent;
	color: #ff6b58;
}
.appie-header-main-menu ul > li > a:hover {
	color: #5956e9;
}
.header-nav-box.header-nav-box-3 .toggle-btn {
	color: #fff;
	font-size: 20px;
}
.header-nav-box.header-nav-box-3.header-nav-box-inner-page
	.appie-btn-box
	.main-btn {
	border-color: #fff;
	background: transparent;
}
.header-nav-box.header-nav-box-3.header-nav-box-inner-page
	.appie-btn-box
	.main-btn:hover {
	background: #fff;
	color: #5956e9;
}
.header-nav-box.header-nav-box-5 .appie-header-main-menu ul > li > a {
	color: #fff;
}
.header-nav-box.header-nav-box-5
	.appie-header-main-menu
	ul
	> li
	.sub-menu
	li
	a {
	color: #0e1133;
}
.header-nav-box.header-nav-box-5 .appie-btn-box .login-btn {
	color: #fff;
}
.header-nav-box.header-nav-box-5 .appie-btn-box .main-btn {
	background: #f84a6e;
	border-color: #f84a6e;
}
.header-nav-box.header-nav-box-5 .appie-btn-box .main-btn:hover {
	background: transparent;
	color: #f84a6e;
}
.header-nav-box.header-nav-box-5 .toggle-btn {
	color: #fff;
	font-size: 20px;
}
.header-nav-box.header-nav-4-box .appie-header-main-menu ul > li > a:hover {
	color: #801f82;
}
.header-nav-box.header-nav-4-box .appie-btn-box .main-btn {
	background: #801f82;
	border-color: #801f82;
}
.header-nav-box.header-nav-4-box .appie-btn-box .main-btn:hover {
	background: transparent;
	color: #801f82;
}
@media (max-width: 767px) {
	.header-nav-box.header-nav-4-box .appie-btn-box ul {
		margin-right: 10px;
	}
}
.header-nav-box.header-nav-4-box .appie-btn-box ul li {
	display: inline-block;
}
.header-nav-box.header-nav-4-box .appie-btn-box ul li a {
	font-size: 18px;
	color: #0e1133;
	margin-left: 15px;
}
.header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn {
	position: relative;
}
.header-nav-box.header-nav-4-box .appie-btn-box ul li a.cart-btn span {
	position: absolute;
	top: -7px;
	display: inline-block;
	right: -12px;
	height: 20px;
	width: 20px;
	text-align: center;
	line-height: 20px;
	color: #fff;
	font-size: 11px;
	background: #801f82;
	border-radius: 50%;
}
.header-nav-box.header-nav-box-6 .main-btn {
	background: #9b2cfa;
	border-color: #9b2cfa;
}
.header-nav-box.header-nav-box-6 .main-btn:hover {
	background: transparent;
	color: #9b2cfa;
}
.header-nav-box.header-nav-box-6 .appie-header-main-menu ul > li > a:hover {
	color: #9b2cfa;
}
.header-nav-box.header-nav-box-6 .appie-btn-box form {
	display: inline-block;
	margin-left: 18px;
}
@media (max-width: 767px) {
	.header-nav-box.header-nav-box-6 .appie-btn-box form {
		display: none;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.header-nav-box.header-nav-box-6 .appie-btn-box form {
		display: inline-block;
	}
}
.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box {
	position: relative;
}
.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box i {
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
}
.header-nav-box.header-nav-box-6 .appie-btn-box form .input-box input {
	width: 105px;
	padding-left: 50px;
	border: 0;
	border-left: 1px solid #ddddea;
	background: transparent;
}
.header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li > a {
	color: #fff;
}
.header-nav-box.header-nav-box-7 .appie-header-main-menu ul > li > a:hover {
	color: #2b70fa;
}
.header-nav-box.header-nav-box-7
	.appie-header-main-menu
	ul
	> li
	.sub-menu
	li
	a {
	color: #505056;
}
.header-nav-box.header-nav-box-7
	.appie-header-main-menu
	ul
	> li
	.sub-menu
	li
	a:hover {
	color: #2b70fa;
}
.header-nav-box.header-nav-box-7 .appie-btn-box .login-btn {
	color: #fff;
}
.header-nav-box.header-nav-box-7 .appie-btn-box .toggle-btn {
	color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-header-main-menu {
		display: none;
	}
}
@media (max-width: 767px) {
	.appie-header-main-menu {
		display: none;
	}
}
.appie-header-main-menu ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.appie-header-main-menu ul > li {
	display: inline-block;
	margin-right: 30px;
	position: relative;
}
.appie-header-main-menu:hover {
	color: red !important;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-header-main-menu ul > li {
		margin-right: 14px;
	}
}
.appie-header-main-menu ul > li > a {
	font-size: 15px;
	font-weight: 500;
	color: #0e1133;
	line-height: 45px;
}
.appie-header-main-menu ul > li > a i {
	padding-left: 6px;
}
.appie-header-main-menu ul > li .sub-menu {
	position: absolute;
	left: 0;
	top: 110%;
	width: auto;
	min-width: 220px;
	max-width: 220px;
	background-color: #fff;
	opacity: 0;
	visibility: hidden;
	transition: all linear 0.3s;
	z-index: 99;
	-webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
	-moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
	box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
	list-style-type: none;
	margin: 0;
	padding: 15px 0;
	border-radius: 5px;
	text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.appie-header-main-menu ul > li .sub-menu {
		min-width: 200px;
		max-width: 200px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-header-main-menu ul > li .sub-menu {
		min-width: 200px;
		max-width: 200px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-header-main-menu ul > li .sub-menu {
		position: relative;
		width: 100%;
		left: 0;
		top: auto;
		opacity: 1;
		visibility: visible;
		display: none;
		right: auto;
		-webkit-transform: translateX(0%);
		-moz-transform: translateX(0%);
		-ms-transform: translateX(0%);
		-o-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transition: all none ease-out 0s;
		-moz-transition: all none ease-out 0s;
		-ms-transition: all none ease-out 0s;
		-o-transition: all none ease-out 0s;
		transition: all none ease-out 0s;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		text-align: left;
		border-top: 0;
		transition: 0s;
		padding: 0;
	}
}
@media (max-width: 767px) {
	.appie-header-main-menu ul > li .sub-menu {
		position: relative;
		width: 100%;
		left: 0;
		top: auto;
		opacity: 1;
		visibility: visible;
		display: none;
		right: auto;
		-webkit-transform: translateX(0%);
		-moz-transform: translateX(0%);
		-ms-transform: translateX(0%);
		-o-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transition: all none ease-out 0s;
		-moz-transition: all none ease-out 0s;
		-ms-transition: all none ease-out 0s;
		-o-transition: all none ease-out 0s;
		transition: all none ease-out 0s;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		text-align: left;
		border-top: 0;
		transition: 0s;
		padding: 0;
	}
}
.appie-header-main-menu ul > li .sub-menu > li {
	position: relative;
	margin-left: 0;
	display: block;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
	margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
		margin-left: 0;
	}
}
@media (max-width: 767px) {
	.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
		margin-left: 0;
	}
}
.appie-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
	color: #0e1133;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.appie-header-main-menu ul > li .sub-menu > li a {
	display: block;
	padding: 0px 30px;
	position: relative;
	color: #505056;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	border-radius: 4px;
	margin: 0 0;
	line-height: 2.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.appie-header-main-menu ul > li .sub-menu > li a {
		padding: 0 20px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-header-main-menu ul > li .sub-menu > li a {
		padding: 0 20px;
	}
}
.appie-header-main-menu ul > li .sub-menu > li a i {
	float: right;
	font-size: 16px;
	margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-header-main-menu ul > li .sub-menu > li a i {
		display: none;
	}
}
@media (max-width: 767px) {
	.appie-header-main-menu ul > li .sub-menu > li a i {
		display: none;
	}
}
.appie-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
	display: inline-block;
}
.appie-header-main-menu ul > li .sub-menu > li a:hover {
	padding-left: 35px;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
	right: auto;
	left: 100%;
	top: 50%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
		padding-left: 30px;
		-webkit-transition: all 0s ease-out 0s;
		-moz-transition: all 0s ease-out 0s;
		-ms-transition: all 0s ease-out 0s;
		-o-transition: all 0s ease-out 0s;
		transition: all 0s ease-out 0s;
	}
}
@media (max-width: 767px) {
	.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
		padding-left: 30px;
		-webkit-transition: all 0s ease-out 0s;
		-moz-transition: all 0s ease-out 0s;
		-ms-transition: all 0s ease-out 0s;
		-o-transition: all 0s ease-out 0s;
		transition: all 0s ease-out 0s;
	}
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li {
	position: relative;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
	right: auto;
	left: 100%;
	top: 50%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
	top: 0%;
	opacity: 1;
	visibility: visible;
}
.appie-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
	top: 0%;
	opacity: 1;
	visibility: visible;
}
.appie-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
	color: #2b70fa;
}
.appie-header-main-menu ul > li .sub-menu > li:hover > a {
	color: #2b70fa;
}
.appie-header-main-menu ul > li:hover .sub-menu {
	opacity: 1;
	visibility: visible;
	top: 100%;
}

@-webkit-keyframes sticky {
	0% {
		top: -200px;
	}
	100% {
		top: 0;
	}
}
@keyframes sticky {
	0% {
		top: -200px;
	}
	100% {
		top: 0;
	}
}
.appie-header-8-area .header-nav-box .appie-btn-box .main-btn {
	background: #db0f30;
	border-color: #db0f30;
}
.appie-header-8-area .header-nav-box .appie-btn-box .main-btn:hover {
	background: #fff;
	color: #db0f30;
}
.appie-header-8-area .header-nav-box .appie-header-main-menu ul > li a:hover {
	color: #db0f30;
}
/* language */
.navlang select {
	margin-right: 40px;
	cursor: pointer !important;
	outline: none;
	border: none;
}
/* The End */
