.admincont {
	margin-left: 20px;
	margin-right: 20px;
}
.maincont {
	margin-top: 90px;
}
.maincont h1 {
	margin-bottom: 40px;
}
.itemsR span {
	margin-right: 5px;
}
.itemsR .Rbtn {
	padding: 30px 20px;
	text-align: center;
}
.us_r {
	background-color: red !important;
	padding: 10px !important;
}
.us_g {
	background-color: green !important;
	padding: 10px !important;
}
.uu {
	padding: 10px !important;
}
.itemsR {
	margin: 20px 0;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	padding-top: 10px;
	padding-bottom: 30px;
	padding-left: 20px;
	border-radius: 5px;
}
.adminuper {
	margin-top: 100px;
}
.mine {
	text-align: center;
	/* padding: 13px 24px; */
	gap: 10px;
	color: #fff;
	width: 141px;
	background: #125bc9;
	border-radius: 40px;
	cursor: pointer;
}
.admininput {
	border: 1px solid #125bc9;
	border-radius: 5px;
	margin-left: 9px;
	padding-left: 9px;
}
.adminbody {
	margin-top: 120px;
}
.adminbody h2 {
	text-align: center;
}
