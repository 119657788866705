
.rate-form-container {
	justify-content: center !important;
}

.loan-form-container .rate-form {
	width: 70%; /* Adjust as needed */
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #e6e6e6;
}





.rate-form-button {
	width: 150px;
	margin: 22px auto auto auto;
	border-radius: 40px !important;
	background-color: #5956e9 !important;
	color: #f6f6f6 !important;
	text-align: center !important;
	border: 0;
}
