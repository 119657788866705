.cbody {
	height: 100vh;
	overflow: hidden;
}
.loan-form-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 100vh;
	padding: 40px;
	column-gap: 20px;
	overflow: hidden !important;
}

.loan-form-container .loan-form {
	width: 50%; /* Adjust as needed */
	padding: 20px;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #e6e6e6;
}

.loan-form-container .progress-bar {
	width: 100%;
	height: 8px;
	background-color: #e0e0e0;
	margin: 20px 0;
}
.loan-form-container .loan-info-content h4 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
}

.loan-form-container .progress {
	height: 100%;
	background-color: #6a4ae3;
	transition: width 0.3s ease-in-out;
}
.loan-info-content p {
	font-size: 16px;
}
.loan-form-container .form-page {
	display: flex;
	flex-direction: column;
	color: #000;
}

.loan-form-container .options-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(120px, 1fr));
	gap: 15px;
	width: 100%;
	margin-top: 20px;
}

.loan-form-container .option-button {
	background-color: #fff;
	color: #000;
	padding: 10px;
	border: 1px solid #e6e6e6 !important;
	border-radius: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: background 0.3s ease-in-out;
}

.loan-form-container .option-button:hover {
	background: #fff;
}
.loan-form-container h2 {
	font-size: 1.2rem;
	margin-bottom: 20px;
}

.loan-form-container .options {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}

.loan-form-container button {
	padding: 10px 15px;
	background-color: #5956e9;
	text-align: left;
	color: #fff;
	font-weight: 300;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.loan-form-container button:hover {
	background-color: #fff;
	color: #000;
	border: 1px solid #5956e9 !important;
}

.loan-form-container input {
	padding: 10px 10px 10px 25px;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
	width: 100%;
}
.loan-form-container .see-more {
	color: #5956e9;
	font-weight: 400;
	font-size: 15px;
	margin-top: 30px;
	margin-bottom: 0;
	cursor: pointer;
}
.loan-form-container .form-navigation {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.loan-form-container button:disabled {
	background-color: #c4c4c4;
	cursor: not-allowed;
}

.loan-form-container .loan-info {
	width: 373px; /* Adjust as needed */
	background-color: #eeeefd;
	padding: 20px;
	border-radius: 24px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loan-form-container .loan-info-content {
	display: flex;
	flex-direction: column;
}

.loan-form-container .loan-info h3 {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.loan-form-container .loan-info p {
	margin-bottom: 15px;
}

.loan-form-container .loan-info p:last-child {
	margin-bottom: 0;
}
.loan-form-container ul li {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
	font-size: 1rem;
	color: #555;
}
.loan-form-container ul li span {
	font-weight: bold;
	color: #000;
	margin-right: 10px;
	padding: 1px;
}
.loan-form-container .form-navigation button {
	border-radius: 40px;
	padding: 6px 20px;
}
.icon {
	background: url("./do.png") no-repeat 10px center;
	background-size: 10px;
	padding-left: 40px; /* Moves the text inside input further right */
	height: 40px; /* Adjust based on input size */
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-sizing: border-box;
}

.input-groups {
	margin-bottom: 20px;
}
.form-sucess {
	text-align: center;
	margin: 110px 0;
}
.form-sucess p {
	width: 450px;
	margin: auto;
}
.form-sucess img {
	display: block;
	margin: 0 auto 15px auto;
}

.form-button {
	width: 100px;
	margin: 12px auto auto auto;
	border-radius: 40px !important;
	background-color: #f6f6f6 !important;
	color: #5956e9 !important;
	text-align: center !important;
	border: 0 s;
}

@media (max-width: 1100px) {
	.form-sucess p {
		width: 100%;
	}
}
@media (max-width: 800px) {
	.loan-form-container {
		flex-direction: column;
		padding: 20px;
		overflow: scroll !important;
		margin: auto;
		min-height: 0;
	}

	.loan-form-container .loan-form {
		width: 100%;
		margin-bottom: 40px;
	}
	.loan-form-container .loan-info {
		width: 100%;
	}
	.cbody {
		overflow: scroll;
	}
}
