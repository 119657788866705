.user_fund p {
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 20px;
}
.userpf {
	color: rgba(255, 255, 255, 0.7);
}
.user_fund h3 {
	font-style: normal;
	font-weight: 600;
	font-size: 40px !important;
	line-height: 49px;
	color: #ffffff;
	text-align: center;
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
	margin-bottom: 60px;
}
.cpr {
	position: relative;
	top: -57px;
}
.withdrawal-container {
	width: 90%;
	margin: auto;
	text-align: center;
	font-family: Arial, sans-serif;
}

.balance {
	font-size: 14px;
	color: gray;
	margin-top: 40px;
}

.balance-amount {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
}

.input-container {
	display: flex;
	align-items: center;
	border: 1px solid #e6e6e6;
	border-radius: 8px;
	padding: 10px;
	position: relative;
	background: white;
}

.currency {
	margin-right: 10px;
	border-right: 1px solid #e6e6e6;
	font-size: 16px;
	color: black;
	padding-right: 10px;
}
.payment-btn {
	text-align: left;
	margin-bottom: 15px;
	padding: 10px 20px;
	border: 1px solid #e6e6e6 !important;
	background-color: transparent;
	border-radius: 8px;
	cursor: pointer;
	transition: all 0.3s ease;
	color: #010101;
}

.payment-options {
	display: flex;
	flex-direction: column;
}
.withdrawal-container > p:first-child {
	text-align: left;
	margin-bottom: 30px;
}
.method_img {
	width: 25px;
	margin-right: 10px;
}
.paypal-withdraw-container,
.crypto-withdraw-container {
	max-width: 510px;
	margin: 80px auto;
	padding: 20px;
	background: white;
	border-radius: 10px;
	border: 1px solid #e6e6e6;
	text-align: left;
}

.paypal-withdraw-container h2,
.crypto-withdraw-container h2 {
	font-size: 20px;
	font-weight: bold;
}

.paypal-withdraw-container p,
.crypto-withdraw-container p {
	font-size: 14px;
	color: #555;
}

.paypal-withdraw-container label {
	display: block;
	font-weight: bold;
	margin-top: 15px;
}

.paypal-withdraw-container input,
.crypin {
	width: 100%;
	padding: 10px;
	margin-top: 5px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 14px;
	outline: none;
}
.crypto-withdraw-container label {
	margin-top: 14px;
	font-size: 14px;
	color: #555;
	margin-bottom: -25px;
}

.crypin {
	margin-bottom: 20px;
	padding: 15px 10px;
}

.paypal-withdraw-container .continue-btn,
.crypto-withdraw-container .continue-btn {
	width: 100%;
	padding: 12px;
	margin-top: 20px;
	border: none;
	border-radius: 25px;
	background: #d1c4e9;
	color: white;
	font-size: 16px;
	cursor: not-allowed;
	transition: 0.3s;
}
.max-bal {
	position: relative;
	height: 45px;
	text-align: right;
}
.selected-crypto {
	border-right: 1px solid #e6e6e6;
	border-left: 1px solid #e6e6e6;
	padding: 15px 15px 0 15px;
	display: flex;
	justify-content: space-between;
}
.inputs-cont {
	display: flex;
	align-items: center;
	border: 1px solid #e6e6e6;
	border-radius: none;
	padding: 10px;
	position: relative;
	background: white;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.select-c p {
	margin-left: 19px;
	color: #6e6e6e;
}
.max-bal p {
	color: #6e6e6e;
}
.gas-f {
	display: flex;
	justify-content: flex-end;
	padding: 10px 15px -10px 10px;
	border: 1px solid #e6e6e6;
	text-align: right;
}
.gas-f p:first-child {
	position: relative;
	top: 18px;
	right: 15px;
}
.cryptext {
	padding: 10px;
	background-color: #ebf6ff;
	display: flex;
}
.cryptext img {
	width: 16.67px;
	height: 16.67px;
	position: relative;
	top: 2px;
	margin-right: 15px;
	left: 7px;
}
.cryptext p {
	color: #223a89;
}

.space-btm {
	margin-bottom: 10px;
}
.paypal-withdraw-container .continue-btn.active,
.crypto-withdraw-container .continue-btn.active {
	background: #9370db;
	cursor: pointer;
}

.payment-btn.selected {
	border: 1.5px solid#5956E9 !important; /* Blue border when selected */
}

input {
	flex: 1;
	border: none;
	font-size: 16px;
	text-align: left;
	outline: none;
}

.max-button {
	background: none;
	border: 1px solid #5956e9 !important;
	color: #010101;
	cursor: pointer;
	padding: 2px 7px;
	border-radius: 4px;
	font-size: 12px;
}

.next-button {
	width: 100%;
	padding: 12px;
	margin-top: 10px;
	background-color: #5a4dff;
	color: white;
	font-size: 16px;
	border: none;
	border-radius: 25px;
	cursor: pointer;
}

.next-button:hover {
	background-color: #4a3dff;
}

@media (max-width: 382px) {
	.max-bal {
		position: relative;
		height: 65px;
	}
	.crypto-withdraw-container p {
		font-size: 11px;
	}
	.inputs-cont {
		padding: 8px;
	}
	.selected-crypto h4 {
		font-size: 16px;
		text-align: right;
	}
}
