.first_dash {
	position: absolute;
	width: 40%;
	height: 305px;
	top: 95px;
	right: 25%;
	border-radius: 20px;
}
.thirld_dash {
	position: absolute;
	width: 20%;
	height: 305px;
	top: 95px;
	right: 15px;
	border-radius: 20px;
}
.thirld_dash a {
	border: #e6e6e6 solid 1px;
	margin: 0 0 20px 0;
	padding: 20px;
}

.img_cont img {
	width: 100%;
	height: 305px;
}
.secound_dash {
	position: absolute;
	width: 30%;
	height: 305px;
	top: 95px;
	background: #433ff7;
	border-radius: 8px;
	color: #fff;
}
.with_dash {
	border: 1px solid #e6e6e6;
	background: #fff !important;
	color: #000;
}
.with_dash .sdash_cont p {
	color: #4a4a4a;
}
.with_dash .dsh_b p span {
	color: #000;
}
.wit_btm {
	border-top: 1px solid #E6E6E6;
	background-color: #fff !important;
}
.ldash_cont,
.ldashcont {
	top: 0;
	position: absolute;
	right: 25px;
}
.user_prof {
	gap: 10px;
	color: #fff;
	font-size: 12px;
	margin-top: 17px;
	width: 101px;
	height: 40px;
	background: #125bc9;
	border-radius: 40px;
	cursor: pointer;
}
.ldash_cont h3 {
	font-size: 28px;
}
.ldash_cont img {
	position: relative;
	left: 22px;
	top: 40px;
}
.dash_port {
	position: relative;
	top: 110px;
}
.dash_port h6:first-child,
.dash_port h3 {
	padding-bottom: 20px;
}
.fdash_cont,
.sdash_cont {
	position: relative;
	top: 33.3px;
	left: 25px;
}

.fdash_cont h2 {
	font-family: "Montserrat-SemiBold" !important;
	margin-top: 20px;
	font-size: 28px;
}
.sdash_cont h3 {
	margin-top: 10px;
	font-size: 30px;
}
.sdash_cont img {
	width: 50px !important;
	position: absolute;
	bottom: 70px;
}

.sdash_cont p {
	color: #fff;
}
.user_btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* padding: 13px 24px; */
	gap: 10px;
	color: #fff;
	margin-top: 130px;
	width: 141px;
	height: 40px;
	background: #125bc9;
	border-radius: 40px;
	cursor: pointer;
}
.user_btn:hover {
	color: white;
	opacity: 0.8;
}
.ldash_panel {
	width: 80% !important;
	top: -107px !important;
	height: 300px !important;
	opacity: 0.6;
	right: -60px !important;
}
.ldash_wif {
	position: absolute !important;
	width: 40px !important;
	top: -60px !important;
	right: 10px !important;
}
.ldashcont img {
	position: relative;
}
.dash_btm {
	display: flex;
	position: relative;
	padding: 20px 0;
	bottom: -50px;
	gap: 25px;
	border-bottom-right-radius: 8px;
	background-color: #5956e9;
	border-bottom-left-radius: 8px;
}
.dsh_btn {
	background-color: #1f1e52;
	width: 30vw;
	padding: 10px 10px;
	color: #fff;
	margin-left: 30px;
	border-radius: 40px;
	text-align: center;
}

.dsh_body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 80%;
	margin-top: 30px;
}
.dsh_b p {
	color: rgba(255, 255, 255, 0.7);
}
.dsh_b p span {
	color: #fff;
}
.dash_btm img {
	position: relative;
	top: 2px;
	width: 60% !important;
}
/* .dash_btm span {
	position: relative;
	right: 30px;
	top: 2px;
} */
.per {
	position: relative !important;
	top: 0 !important;
	width: 7px !important;
	left: 5px !important;
}

/* Image Slide */
.each-slide-effect > div {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	height: 350px;
}
.sdm {
	display: none;
}

.each-slide-effect span {
	padding: 20px;
	font-size: 20px;
	background: #efefef;
	text-align: center;
}
/* End image Slide */
@media (max-width: 1034px) {
	.ldash_cont span {
		margin-left: -25px;
	}
	.first_dash {
		width: 55%;
		right: 0;
	}
	.secound_dash {
		width: 40%;
	}
	.thirld_dash {
		display: none;
	}
	.sdm {
		display: block;
	}
}
@media (max-width: 870px) {
	.ldash_cont span {
		margin-left: -50px;
	}
}
@media (max-width: 818px) {
	.secound_dash {
		width: 100%;
		margin: auto;
	}
	.first_dash {
		display: none;
	}
	.dsh_btn {
		width: 80vw;
	}
	.ldash_cont span {
		margin-left: 15px;
	}
}
@media (max-width: 553px) {
	.ldash_cont p,
	.first_dash p {
		font-size: 13px;
	}
	.dsh_btn {
		width: 60vw;
	}
	.ldash_cont span {
		margin-left: -40px;
	}
	.fdash_cont h2 {
		font-size: 25px;
	}
	.user_btn {
		width: 116px;
		font-size: 12px;
	}
	.fdash_cont {
		left: 23px;
	}
}
@media (max-width: 553px) {
	.cont_port {
		display: none;
	}
}
@media (max-width: 321px) {
	.cont_port {
		display: none;
	}
	.fdash_cont h2 {
		margin-top: 5px !important;
	}
	.user_btn {
		margin-top: 155px;
	}
}
