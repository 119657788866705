.users_modal {
	position: relative;
	width: 520px;
	margin: auto !important;
	height: 527px;
	top: 20px;
	background: #fff;
	border-radius: 20px;
	color: #000;
	padding: 30px 60px 60px 60px;
	border: 1px solid rgba(230, 230, 230, 1);
}

.set_userp {
	display: flex;
	margin-bottom: 30px;
}
.settings_content,
.settings_contents {
	width: 85vw;
	margin-top: 20px;
	display: flex;
	gap: 80px;
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(247, 247, 247, 1);
}
.settings_content b {
	text-transform: capitalize;
	width: 90%;
}
.settings_contents b {
	width: 90%;
}
.settings_content span,
.settings_contents span {
	font-weight: 100;
	color: rgba(133, 133, 130, 1);
	width: 100%;
}
.user_p {
	margin: 30px 0 0 0;
	width: 50%;
}
.mo_body {
	width: 90%;
	text-align: center;
	margin: 30px auto 40px auto;
}
.mo_body b a {
	color: #000;
}
.mo_btn {
	background-color: rgba(89, 86, 233, 1);
	border-radius: 40px;
	padding: 12px;
	color: #fff;
	width: 90%;
	margin-bottom: 30px;
}
.mo_body img {
	margin: 20px;
}
.user_p button {
	margin-top: 50px;
	padding: 10px 20px;
	border: 1.5px solid rgba(230, 230, 230, 1) !important;
	background-color: transparent;
	border-radius: 24px;
}
.set_userp .user_initials {
	width: 56px;
	height: 56px;
}
.set_userp h4 {
	color: rgba(1, 1, 1, 1);
	position: relative;
	font-size: 15px;
	font-weight: 500;
	top: 19px;
	left: 15px;
}

.set_pass {
	background-color: rgba(247, 247, 247, 1);
	margin-top: 50px;
	padding: 40px 80px 40px 40px;
	width: 85vw;
}

.set_pass button {
	border: none !important;
	color: rgba(89, 86, 233, 1);
	padding: 0;
	margin: 0;
}
.newhistory {
	margin-top: 50px;
}
.newhistory .transct {
	border: none;
}
.circle {
	margin-right: 5px;
	width: 8px; /* Set equal width */
	height: 8px; /* Set equal height */
	background-color: rgba(17, 142, 62, 1); /* Apply your color */
	border-radius: 50%; /* Makes the element round */
}
.yellow {
	margin-right: 5px;
	width: 8px; /* Set equal width */
	height: 8px; /* Set equal height */
	background-color: #ffd60a; /* Apply your color */
	border-radius: 50%; /* Makes the element round */
}
.red {
	margin-right: 5px;
	width: 8px; /* Set equal width */
	height: 8px; /* Set equal height */
	background-color: #ee2737; /* Apply your color */
	border-radius: 50%; /* Makes the element round */
}
.sta {
	border-radius: 40px;
	padding: 5px 10px;
	border: 1.5px solid rgba(230, 230, 230, 1);
}
.UserHistory .sta {
	padding: 2px 5px !important;
}
.status {
	border-radius: 40px;
	padding: 3px 10px;
	background-color: rgba(255, 235, 239, 1);
	color: #ae132b;
}
.filter-buttons {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}

.filter-buttons button {
	background: none;
	border: 1px solid #e6e6e6;
	padding: 10px 15px;
	font-size: 16px;
	cursor: pointer;
	transition: color 0.3s, border-bottom 0.3s;
}
.top-pen {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}
.paginationActive {
	display: none;
}
.top-per-cont h4,
.top-per-cont h3,
.top-per-cont p {
	font-size: 15px;
}
.top-pen img {
	width: 40px;
	height: 40px;
	position: relative;
	top: 20px;
	margin-right: 15px;
}
.top-per-cont {
	border: 1px solid #e6e6e6;
	border-radius: 8px;
	padding: 20px;
	display: flex;
}

.filter-buttons button.active {
	color: #5956e9;
	border-bottom: 2px solid #5956e9 !important;
	font-weight: bold;
}

.pend {
	background-color: #fffae5;
	color: #8a6c00;
}
.sucess {
	background-color: #eafee1;
	color: #118e3e;
}
@media (max-width: 1064px) {
	.top-pen {
		display: grid;
		grid-template-columns: repeat(2, 1fr); /* 2 items per row */
		gap: 20px;
	}
}

@media (max-width: 769px) {
	.users_modal {
		width: 445px;
	}
	.histpg {
		font-size: 14px;
	}
	.paginationBttns a {
		margin-left: 0 !important;
	}
}
@media (max-width: 600px) {
	.top-pen {
		grid-template-columns: repeat(1, 1fr); /* 1 item per row */
	}
}
@media (max-width: 525px) {
	.histpg {
		font-size: 12px;
	}
	.filter-buttons {
		display: grid;
		grid-template-columns: repeat(1, 1fr); /* 2 items per row */
		gap: 20px;
	}
}

@media (max-width: 480px) {
	.users_modal {
		width: 100% !important;
		padding: 30px 30px 60px 30px;
	}
	.settings_content,
	.settings_contents {
		width: 90vw;
	}
}
@media (max-width: 413px) {
	.col-date,
	.col-amount,
	.col-status,
	.col-loan-type,
	.col-description {
		font-size: 10.5px;
	}
	.sta {
		padding: 0 2px;
	}
	.circle {
		margin-right: 3px;
	}
	.yellow {
		margin-right: 3px;
	}
	.red {
		margin-right: 3px;
	}
	.foot_showing span {
		display: none;
	}
	.set_userp .user_initials {
		width: 35px;
		height: 35px;
	}
	.set_userp h4 {
		top: 9px;
	}
	.settings_content, .settings_contents {
        width: 100%;
		gap: 0;
    }
	.user_p {
		width: 100%;
	}
	.set_pass {
		padding: 40px 20px;
	}
}
@media (max-width: 340px) {
	.foot_showing {
		display: none;
	}
	.paginationBttns {
		justify-content: space-between;
	}
	.user_p button {
		padding: 5px 10px;
	}
}
@media (max-width: 322px) {
	.sta {
		border: none;
	}
}
@media (max-width: 316px) {
	.users_modal {
		padding: 30px 20px 60px 20px;
	}
}

/* Users Dropdown */
.users_drop {
	margin: auto auto 25px auto;
	width: 100%;
}

.users_drop select,
.users_drop input {
	box-sizing: border-box;
	background-color: transparent;
	padding: 0 20px;
	width: 100%;
	height: 40px;
	color: #fafafa !important;
	margin: auto;
	outline: none;
	opacity: 0.5;
	border: 1px solid rgba(250, 250, 250, 0.7);
	border-radius: 40px !important;
}
.users_drop input::placeholder {
	color: #fafafa;
}
.users_drop select {
	cursor: pointer;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	background-image: url(./arrow.svg);
	background-position: 94%;
	background-repeat: no-repeat;
}
.user_submit input {
	background: #125bc9;
	color: #fff !important;
	opacity: 1;
	border: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
.abso {
	margin-top: -17px;
}
.color_red {
	font-weight: bold !important;
	color: red !important;
}
.plan_up {
	margin-top: 10px;
	color: #fff;
	font-weight: bolder;
	cursor: pointer;
	width: 100%;
	outline: none !important;
	border: none !important;
}
.plan_up:hover {
	opacity: 0.8;
}
.user_fund,
.user_backup {
	text-align: center;
}
.cryp_img {
	width: 148px !important;
	margin-top: 20px;
	margin-bottom: 20px;
}
.user_fundbtm {
	margin-top: 10px;
	text-align: left !important;
}
.user_fundbtm h6 {
	width: 20% !important;
	padding-top: 5px;
}
.users_btn {
	background: #125bc9;
	color: #fff !important;
	opacity: 1;
	width: 100%;
	/* border: 1px solid rgba(250, 250, 250, 0.7); */
	border-radius: 40px;
	text-align: center;
	cursor: pointer;
	margin-top: 25px;
	padding: 5px 0;
}
.user_with h2 {
	margin: 20px 0 25px 0;
}
.with_btn {
	margin-top: 40px;
}
.user_backup h6 {
	margin: 25px 0 10px 0;
}
.passicon {
	margin-bottom: 50px;
}
.display {
	display: block;
}
.hide {
	display: none;
}
.user_back:first-child h6 {
	margin-bottom: 30px;
	margin-top: 30px;
}
.switch {
	text-align: left;
}
.switch span {
	width: 95%;
	position: relative;
	top: 6px;
	left: 15px;
	margin: 40px 0;
	font-size: 13px;
	line-height: 15px;
}

/* Backup */
.B_phrase {
	width: 80%;
	position: relative;
	top: 40px;
	margin: 10px auto 35px auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(89px, 1fr));
	color: #fff !important;
}
@media screen and (max-width: 769px) {
	.B_phrase {
		width: 90% !important;
		grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)) !important;
	}
}

.phrases {
	width: 95% !important;
	padding: 10px -1px;
	margin: 6px;
	border: 1px solid #fafafa !important;
	border-radius: 40px;
	box-sizing: border-box;
}
.blue_btn {
	background: #125bc9 !important;
	color: #fff !important;
	border: none;
}
.numbering {
	color: rgba(220, 214, 234, 0.5);
	padding-right: 4px;
}
.copy_btn button {
	margin-top: 30px !important;
	outline: none;
	background-color: #5956e9 !important;
	padding: 10px;
}
.copy_btn img {
	margin-right: 10px;
	width: 15px;
}
.account_m img {
	width: 150px;
	margin-top: 10px;
}
/* .accounM button {
	margin-top: 30px !important;
} */
/* Referrals */
.giftbox {
	width: 30px !important;
	margin-top: -5px;
}
.referbox {
	margin-top: 45px;
	width: 100%;
	height: 89px;
	background: #1f1e52;
	color: #fff;
	border-radius: 30px;
	position: relative;
}
.refer_left {
	position: absolute;
	top: 12px;
	left: 25px;
}
.refer_right {
	position: absolute;
	top: 3px;
	right: 45px;
}
.refer_left h5 {
	font-family: "Montserrat-Bold" !important;
	font-size: 28px !important;
}
.refer_left p {
	position: absolute;
	top: 38px;
	left: 20px;
}
.refer_right h3 {
	position: relative;
	right: 30px;
	font-size: 25px !important;
}
.refer_right p {
	position: absolute;
	bottom: 25px;
	right: 5px;
}
.refer_mid {
	margin-top: 40px;
	width: 55px;
	height: 0px;
	top: 40px;
	left: 140px;
	opacity: 0.4;
	border: 0.8px solid #fafafa;
	transform: rotate(90deg);
}
.refercode {
	margin-top: 30px;
	opacity: 0.5;
}
.refercode img {
	width: 30px !important;
}
