.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}

.main {
	display: flex;
	flex-direction: column;
	gap: 40px;
}
.loan-sec {
	background-color: rgba(204, 203, 248, 0.2);
	padding: 10px 90px 50px 90px;
	margin-top: 60px;
}
.loan-sec h3,
.why-sec h3 {
	text-align: center;
	padding: 50px 0;
}

.why-sec {
	padding: 10px 90px 50px 90px;
	margin-top: 60px;
}

.loan-process,
.why-choose {
	display: flex;
	align-items: center;
	gap: 20px;
}

.loan-process .image-container,
.why-choose .image-container {
	flex: 1;
}

.loan-process .text-content,
.why-choose .text-content {
	flex: 1;
}

.loan-process {
	flex-direction: row-reverse; /* Loan Process: Image on the left */
}

.why-choose {
	flex-direction: row-reverse; /* Why Choose: Image on the right */
}

.text-content h3 {
	font-size: 1.8rem;
	margin-bottom: 15px;
	color: #333;
}

.text-content ul {
	list-style: none;
	padding: 0;
}

.text-content ul li {
	display: flex;
	align-items: flex-start;

	margin-bottom: 10px;
	font-size: 1rem;
	color: #555;
}

.text-content ul li span {
	font-weight: bold;
	color: #000;
	margin-right: 20px;
}

.image-container img {
	/* width: 100%; */
	height: auto;
	width: 527px;
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button {
	margin-top: 15px;
	padding: 10px 20px;
	margin-left: 50px;
	font-size: 1rem;
	color: #fff;
	background-color: #007bff;
	border-radius: 40px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.button:hover {
	background-color: #0056b3;
}
@media (max-width: 991px) {
	.image-container img {
		width: 90% !important;
	}
	.loan-sec,
	.why-sec {
		padding: 50px !important;
	}
}
@media (max-width: 965px) {
	.loan-process {
		flex-direction: column;
	}
	.why-choose {
		flex-direction: column;
	}
	.text-content {
		margin-top: 30px;
	}
}
@media (max-width: 555px) {
	.loan-sec,
	.why-sec {
		padding: 10px !important;
	}
	.image-container img {
		width: 100% !important;
	}
}
